@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;400;500;600;700;900&display=swap");

body {
  margin: 0;
  font-family: "Mulish", "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h3 {
  font-weight: 700;
}
