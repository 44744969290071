* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  margin: 0;
}

main {
  max-width: 1440px;
  margin: 0 auto;
  color: #212529;

  font-size: 16px;
  overflow-x: hidden;

  flex: 1;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

img {
  width: 100%;
}

/* general */

.nav-back {
  width: 100%;
  justify-self: flex-start;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.nav-back a {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-self: flex-G601start;
  margin-bottom: 20px;
}

.nav-back span > {
  display: block;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #555;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loader {
  margin-top: 100px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  font-weight: bold;
  text-align: center;
  padding-top: 80px;
}

/* page padding */

.page-padding {
  padding: 0px 20px;
  min-height: calc(100vh - 60px);
}

/* header */

.header-wrapper-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.header-wrapper-nav a {
  text-decoration: none;
  padding: 8px 16px;
  transition: color 0.3s ease;
}

.header-wrapper-nav a.active {
  color: #000;
  font-weight: 600;
}

.header-wrapper-nav a:hover {
  color: #000;
  font-weight: 600;
}

/* hero sections */

.hero-section {
  padding-top: calc(6rem + 60px);
}

.hero-section-bg {
  max-width: 550px;
  margin: 30px auto;
}

.image-container {
  perspective: 1000px;
  margin: 40px auto;
}

.image-3d {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.1s ease-out;
  will-change: transform;
}

.hero-section-nav {
  text-align: center;
}

.hero-section-nav a {
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* spaces section */

.cards-container {
  column-count: 4;
  column-gap: 14px;
  height: 100%;
}

.spaces-card {
  margin: 0;
  display: grid;
  gap: 4px;
  grid-template-rows: 1fr auto;
  margin-bottom: 24px;
  page-break-inside: avoid;
  break-inside: avoid;
  position: relative;
}

.spaces-card-img img {
  transition: all 300ms ease;
}

.spaces-card:hover img {
  transform: scale(1.1);
}

.filter-btns-wrapper::-webkit-scrollbar {
  display: none;
}

.filter-btns .filter-btn {
  cursor: pointer;
  display: block;
  color: inherit;
  background-color: #f0f0f0;
  border: none;
  border-radius: 5px;
  padding: 6px 12px;
  min-height: 36px;
  font-size: 14px;
  white-space: nowrap;
  transition: background-color 0.3s ease, transform 0.2s;
}

.filter-btns .filter-btn:hover {
  background-color: #343a40;
  color: #fff;
}

.filter-btns .filter-btn.active {
  background-color: #212529;
  color: #fff;
  transform: scale(0.98);
}

.pagination-btns .pagination-btn:hover {
  background-color: #343a40;
  color: #fff;
  transform: translateY(-2px);
}

/* submit page */

.submit-page {
  padding: 8rem 0;
}

.submit-page-container {
  max-width: 660px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
}

.form-container {
  width: 100%;
  padding: 60px 30px;
  background-color: #e9ecef;
  border-radius: 12px;
}

.form-header-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.submit-page-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.submit-page-form input {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  min-height: 36px;
  font-size: 14px;
  padding: 5px 7px;
  border: 0px solid #212529;
  border-radius: 5px;
  background-color: #fff;
  margin-top: 6px;
  padding-left: 10px;
}

.form-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.submit-page-form .form-btn {
  cursor: pointer;
  width: 100%;
  display: block;
  background-color: #212529;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 7px;
  min-height: 36px;
}

.admin-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #6c757d;
}

.admin-login-btn:hover {
  color: #212529;
}

/* post page */

.postpage-header p {
  font-weight: 600;
}

.postpage-header .edit-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #212529;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 7px 14px;
  min-height: 36px;
}

.postpage span {
  color: #6c757d;
  font-weight: lighter;
}

.postpage-credits p {
  font-weight: 600;
}

.credits-handle > p {
  display: inline-block;
  margin-right: 10px;
}

.postpage-description a {
  border-bottom: 1px solid #e5e5e5;
}

.postpage-description a:hover {
  color: #1e9751;
}

.postpage-links p {
  padding-top: 6px;
  width: fit-content;
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease;
}

.postpage-links p a {
  border-bottom: 1px solid #e5e5e5;
}

.postpage-links p:hover {
  color: #1e9751;
}

.social-links p a {
  border-bottom: 1px solid #e5e5e5;
}

.social-links p {
  display: inline-block;
}

.social-links p:hover {
  color: #1e9751;
}

.related-post:hover .related-post-img {
  scale: 1.1;
}

/* footer */
.footer-wrapper-base b {
  color: #555;
  transition: color 0.3s ease, transform 0.3s ease, text-shadow 0.3s ease;
}

.footer-wrapper-base b:hover {
  color: #fff;
  transform: scale(1.1);
  text-shadow: 0 0 2px #000, 0 0 5px #000;
}

/* media-quries */
@media only screen and (max-width: 1200px) {
  .cards-container {
    column-count: 3;
    column-gap: 14px;
  }

  .header-wrapper-nav {
    gap: 16px !important;
  }
}

@media only screen and (max-width: 992px) {
  .post {
    flex-direction: column !important;
    gap: 20px !important;
  }

  .postpage-info,
  .postpage-img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .cards-container {
    column-count: 2 !important;
    column-gap: 10px;
  }

  .submit-page {
    padding: 6rem 0;
  }

  .spaces-card {
    margin-bottom: 20px !important;
  }

  .header-wrapper-nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: rgba(255, 255, 255);
    padding: 10px;
    width: 100%;
    z-index: 1;
    border-bottom: 1px solid #ececec;
    border-radius: 0 0 12px 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    gap: 10px !important;
  }

  .header-wrapper-nav a {
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .header-wrapper-nav a:hover {
    background-color: whitesmoke;
    border-radius: 6px;
  }

  .header-wrapper-nav.active {
    display: flex;
  }

  .header-wrapper-social:hover a {
    border-bottom: 1px solid #e5e5e5 !important;
    color: #1e9751 !important;
  }

  .spaces-item {
    position: absolute;
    bottom: 30px;
    right: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(1px);
    padding: 2px 8px;
    border-radius: 10px;
    font-size: 12px !important;
    color: #212529 !important;
  }

  .filter-btns .filter-btn {
    font-size: 13px !important;
  }

  .hero-section {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .footer-wrapper {
    flex-direction: column;
    gap: 60px;
    align-items: flex-start;
    font-size: 15px !important;
  }
}
